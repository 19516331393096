import moment from 'moment';
import client from '../../libs/client';

export default class BillingNewGateway {
    getBillingUrlBase = (operationId) => {
        return `/operations/${operationId}/billing`;
    };

    getBillableEntities = async (operationId) => {
        const { data: billableEntities } = await client.get(
            `${this.getBillingUrlBase(operationId)}/billable-entities`
        );
        return billableEntities;
    };

    getBillingEntities = async (operationId) => {
        const { data: billingEntities } = await client.get(
            `${this.getBillingUrlBase(operationId)}/billing-entities`
        );
        return billingEntities;
    };

    createBillingEntity = async (operationId, billingEntity) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/billing-entities`,
            billingEntity
        );
        return res;
    };

    updateBillingEntity = async (operationId, billingEntity) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/billing-entities/${
                billingEntity.id
            }`,
            billingEntity
        );
        return res;
    };

    createBillableEntity = async (operationId, billableEntity) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/billable-entities`,
            billableEntity
        );
        return res;
    };

    updateBillableEntity = async (operationId, billableEntity) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/billable-entities/${
                billableEntity.id
            }`,
            billableEntity
        );
        return res;
    };

    getInvoices = async (operationId) => {
        const { data: invoices } = await client.get(
            `${this.getBillingUrlBase(
                operationId
            )}/invoices?consumerIds=&producerIds=`
        );
        return invoices;
    };

    getLastInvoiceDate = async (
        operationId,
        billingEntityId,
        billableEntityId
    ) => {
        const { data: lastInvoiceDate } = await client.get(
            `${this.getBillingUrlBase(
                operationId
            )}/invoices/lastInvoiceDate?billingEntityId=${billingEntityId}&billableEntityId=${billableEntityId}
            `
        );
        return lastInvoiceDate;
    };

    getInvoiceDownloadURL = async (operationId, invoiceId, language) => {
        const { data: url } = await client.get(
            `${this.getBillingUrlBase(
                operationId
            )}/invoices/${invoiceId}/download?language=${
                language ? language : ''
            }`
        );
        return url;
    };

    generateInvoices = async (operationId, date) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/invoices/generate`,
            {
                billUpTo: date ? date : moment().format('YYYY-MM-DD'),
            }
        );
        return res.data;
    };

    deleteInvoice = async (operationId, invoiceId) => {
        const res = await client.delete(
            `${this.getBillingUrlBase(operationId)}/invoices/${invoiceId}`
        );
        return res;
    };

    publishInvoice = async (operationId, invoiceId) => {
        const res = await client.post(
            `${this.getBillingUrlBase(
                operationId
            )}/invoices/${invoiceId}/publish`
        );
        return res;
    };

    getBillingPlans = async (operationId) => {
        const { data: billingPlans } = await client.get(
            `${this.getBillingUrlBase(operationId)}/plans`
        );
        return billingPlans;
    };

    createBillingPlan = async (operationId, billingPlan) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/plans`,
            billingPlan
        );
        return res;
    };

    updateBillingPlan = async (operationId, billingPlan) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/plans/${billingPlan.id}`,
            billingPlan
        );
        return res;
    };

    getBillingSubscriptions = async (operationId) => {
        const { data: billingSubscriptions } = await client.get(
            `${this.getBillingUrlBase(operationId)}/subscriptions`
        );
        return billingSubscriptions;
    };

    createBillingSubscription = async (operationId, billingSubscription) => {
        const res = await client.post(
            `${this.getBillingUrlBase(operationId)}/subscriptions`,
            billingSubscription
        );
        return res;
    };

    updateBillingSubscription = async (operationId, billingSubscription) => {
        const res = await client.put(
            `${this.getBillingUrlBase(operationId)}/subscriptions/${
                billingSubscription.id
            }`,
            billingSubscription
        );
        return res;
    };
}
