import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
    List,
    ListItem,
    Collapse,
    Grid,
    Tooltip,
    IconButton,
    Select,
    MenuItem,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Alert from '@material-ui/lab/Alert';

import { AllocationAlgorithmTypeEnum } from '../../../../../../../app-config';
import { selectActiveConsumersSinceLastMensiversary } from '../../../../../../corelogic/redux/selectors/participantSelectors';
import {
    getParticipantsAllocationParameters,
    setNewParticipantsParameters,
} from '../../../../../../corelogic/redux/actions';
import {
    selectNewAllocationAlgorithmType,
    selectPersistedAllocationAlgorithmConfig,
    selectPersistedParticipantsParameters,
} from '../../../../../../corelogic/redux/selectors/energyAllocationSelector';

import ConfigTable, { getParamType } from './ConfigTable';

export const SURPLUS_GROUPE_NAME = 'surplus';

// New pool should not have id when sending to back, then use temporary id
export const NEW_POOL_PREFIX = 'new_pool_';

const NEW_POOL_NAME_PREFIX = 'Groupe ';

const useStyles = makeStyles((theme) => ({
    poolCell: {
        margin: '0px 15px',
    },
    pool2mergedCells: {
        margin: '0px 30px',
    },
    warning: {
        color: '#ffb74d',
    },
}));

const UP = 'move_pool_up';
const DOWN = 'move_pool_down';
const DELETE = 'delete_pool';

const availableAlgorithms = [
    AllocationAlgorithmTypeEnum.BY_DEFAULT,
    AllocationAlgorithmTypeEnum.BY_PRIORITY,
    AllocationAlgorithmTypeEnum.BY_SHARE_WITH_MINIMUM,
];

const nRowsForConfig = 1;
const nRowsForName = 3;
const nRowsForProd = 1;
const nRowsForSurplus = 3;
const nRowsForAlgorithm = 2;
const nRowsForTools = 2;

const PoolDocumentation = () => {
    return (
        <Alert icon={<PlaylistAddCheckIcon />} severity="success">
            <h3 style={{ marginTop: 0, marginBottom: 0 }}>Règles</h3>
            <ul>
                <li>Priorisation des groupes</li>
                <ul>
                    <li>
                        Les groupes sont affichés par ordre de priorité (le plus
                        haut, le plus prioritaire)
                    </li>
                    <li>
                        Pour chaque groupe, il est possible d'augmenter sa
                        priorité (
                        <ArrowUpwardIcon style={{ fontSize: 'medium' }} />
                        ) ou de la baisser (
                        <ArrowDownwardIcon style={{ fontSize: 'medium' }} />
                        ),
                    </li>
                    <li>
                        Un groupe ne peut pas donner son surplus à un groupe au
                        dessus de lui,
                    </li>
                </ul>
                <li>
                    La configuration de chaque participant se fait en cliquant
                    sur
                    <ExpandMore style={{ fontSize: 'medium' }} />
                    <GroupIcon style={{ fontSize: 'medium' }} /> (le chiffre
                    après est le nombre de participants),
                </li>
                <li>
                    Un participant est ajouté dans un groupe lorsqu'on lui
                    attribue un paramètre (case cochée, une priorité, une
                    part...),
                </li>
                <li>
                    Enfin, on peut ajouter un groupe avec le bouton{' '}
                    <i>
                        "
                        <AddIcon style={{ fontSize: 'medium' }} /> Ajouter un
                        nouveau groupe"
                    </i>{' '}
                    en bas de la liste et en supprimer via le bouton
                    <DeleteIcon style={{ fontSize: 'medium' }} /> en fin de
                    ligne,
                </li>
            </ul>
        </Alert>
    );
};

const AlgorithmSelector = ({
    availableAlgorithms,
    selectedPool,
    handleUpdateAlgo,
}) => {
    const id = selectedPool.id;
    const prefix = `pool-${id}-algo`;
    return (
        <Select
            labelId={`${prefix}-selector`}
            id={`${prefix}-selector`}
            key={`${prefix}-selector`}
            value={selectedPool.algorithm ?? ' '}
            error={selectedPool.algorithm === null}
            fullWidth
            style={{ fontSize: '14px' }}
            onChange={(e) => handleUpdateAlgo(id, e.target.value)}
        >
            <MenuItem value={' '} key={`${prefix}-undefined`}>
                {' '}
            </MenuItem>
            {availableAlgorithms.map((algo) => {
                return (
                    <MenuItem value={algo} key={`${prefix}-choice-${algo}`}>
                        {t(`enoapp.technical.coefficients.${algo}`)}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

const SurplusDestinationSelector = ({
    pools,
    selectedPool,
    handleUpdateSurplusDestination,
}) => {
    const poolIndex = pools.findIndex((pool) => pool.id === selectedPool.id);

    const poolId = selectedPool.id;
    const availableDestinations = [
        ...pools.slice(poolIndex + 1, pools.length).map((pool) => {
            return {
                name: pool.name,
                value: pool.name,
            };
        }),
        {
            name: 'Surplus',
            value: SURPLUS_GROUPE_NAME,
        },
    ];
    const prefix = `pool-${poolId}-surplus`;

    return (
        <Select
            labelId={`${prefix}-selector`}
            id={`${prefix}-selector`}
            key={`${prefix}-selector`}
            value={selectedPool.surplusDestinationPoolName ?? ' '}
            error={selectedPool.surplusDestinationPoolName === null}
            fullWidth
            style={{ fontSize: '14px' }}
            onChange={(e) =>
                handleUpdateSurplusDestination(poolId, e.target.value)
            }
        >
            <MenuItem value={' '} key={`${prefix}-undefined`}>
                {' '}
            </MenuItem>
            {availableDestinations.map((destination) => {
                return (
                    <MenuItem
                        value={destination.value}
                        key={`${prefix}-${destination.value}`}
                    >
                        {destination.name}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

const PoolTools = ({ handleMovePool, poolId, isFirst, isLast }) => {
    return (
        <>
            {/* FIXME: Warning console quand on passe la souris sur le tooltip, la première fois*/}
            <Tooltip title={'Augmenter la priorité de ce groupe'}>
                <span>
                    <IconButton
                        disabled={isFirst}
                        onClick={() => handleMovePool(poolId, UP)}
                    >
                        <ArrowUpwardIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={'Baisser la priorité de ce groupe'}>
                <span>
                    <IconButton
                        disabled={isLast}
                        onClick={() => handleMovePool(poolId, DOWN)}
                    >
                        <ArrowDownwardIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={'Supprimer ce groupe'}>
                <span>
                    <IconButton onClick={() => handleMovePool(poolId, DELETE)}>
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
};

const HeaderLine = () => {
    const classes = useStyles();
    return (
        <ListItem>
            <Grid item xs={nRowsForConfig}></Grid>
            <Grid item xs={nRowsForName} className={classes.poolCell}></Grid>
            <Grid item xs={nRowsForProd} className={classes.poolCell}>
                Production affectée
            </Grid>
            <Grid item xs={nRowsForSurplus} className={classes.poolCell}>
                Destination du surplus
            </Grid>
            <Grid item xs={nRowsForAlgorithm} className={classes.poolCell}>
                Algorithme
            </Grid>
            <Grid item xs={nRowsForTools}></Grid>
        </ListItem>
    );
};

const PoolLine = ({
    pools,
    pool,
    participants,
    handleUpdateName,
    handleUpdateAlgo,
    handleUpdateProductionPercentage,
    handleUpdateSurplusDestination,
    handleMovePool,
    handleParamChange,
}) => {
    const classes = useStyles();
    const prefix = `pool-${pool.id}`;
    const nConsumersInPool = Object.keys(
        pool.withinPoolProductionParams
    ).length;

    const [open, setOpen] = useState(false);
    return (
        <>
            <ListItem>
                <Grid item xs={nRowsForConfig}>
                    <ListItem
                        button
                        onClick={() => setOpen(!open)}
                        className={
                            nConsumersInPool === 0 ? classes.warning : ''
                        }
                    >
                        {open ? <ExpandLess /> : <ExpandMore />}
                        <GroupIcon />
                        {nConsumersInPool}
                    </ListItem>
                </Grid>
                <Grid item xs={nRowsForName} className={classes.poolCell}>
                    <TextField
                        type="text"
                        id={`${prefix}-name-${pool.id}`}
                        value={pool.name}
                        fullWidth
                        onChange={(e) =>
                            handleUpdateName(pool.id, e.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={nRowsForProd} className={classes.poolCell}>
                    <TextField
                        type="number"
                        id={`${prefix}-prod-percentage`}
                        value={pool.percentageProduction}
                        onChange={(e) =>
                            handleUpdateProductionPercentage(
                                pool.id,
                                e.target.value
                            )
                        }
                        InputProps={{
                            className:
                                pool.percentageProduction === 0
                                    ? classes.warning
                                    : '',
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        placeholder={'[0-100]'}
                    />
                </Grid>
                <Grid item xs={nRowsForSurplus} className={classes.poolCell}>
                    <SurplusDestinationSelector
                        pools={pools}
                        selectedPool={pool}
                        handleUpdateSurplusDestination={
                            handleUpdateSurplusDestination
                        }
                    />
                </Grid>
                <Grid item xs={nRowsForAlgorithm} className={classes.poolCell}>
                    <AlgorithmSelector
                        availableAlgorithms={availableAlgorithms}
                        selectedPool={pool}
                        handleUpdateAlgo={handleUpdateAlgo}
                    />
                </Grid>
                <Grid item xs={nRowsForTools}>
                    <PoolTools
                        handleMovePool={handleMovePool}
                        poolId={pool.id}
                        isFirst={pools[0].id === pool.id}
                        isLast={pools[pools.length - 1].id === pool.id}
                    />
                </Grid>
            </ListItem>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                style={{ margin: '0 50px' }}
            >
                <ParamTable
                    prefix={prefix + '-' + pool.algorithm}
                    pool={pool}
                    participants={participants}
                    handleParamChange={handleParamChange}
                />
            </Collapse>
        </>
    );
};

const ParamTable = ({ prefix, pool, participants, handleParamChange }) => {
    switch (pool.algorithm) {
        case AllocationAlgorithmTypeEnum.BY_DEFAULT:
        case AllocationAlgorithmTypeEnum.BY_PRIORITY:
        case AllocationAlgorithmTypeEnum.BY_SHARE_WITH_MINIMUM:
            return (
                <ConfigTable
                    prefix={prefix}
                    paramType={getParamType(pool.algorithm)}
                    participants={participants}
                    participantParams={pool.withinPoolProductionParams}
                    paramIsPercentage={
                        pool.algorithm === AllocationAlgorithmTypeEnum.static
                    }
                    handleParamChange={handleParamChange}
                    maxHeight={'400px'}
                />
            );
        default:
            return (
                <Alert severity="error" style={{ marginTop: '10px' }}>
                    Merci de sélectionner un algorithme pour ce groupe;
                </Alert>
            );
    }
};

const SurplusLine = ({ pools }) => {
    const classes = useStyles();
    const surplusPercentage = pools
        .reduce((acc, pool) => acc - pool.percentageProduction, 100)
        .toString();
    return (
        <ListItem>
            <Grid
                item
                xs={nRowsForConfig + nRowsForName}
                className={classes.poolCell}
                style={{ textAlign: 'center' }}
            >
                {SURPLUS_GROUPE_NAME}
            </Grid>
            <Grid item xs={nRowsForProd} className={classes.poolCell}>
                <TextField
                    type="tel"
                    id={`${SURPLUS_GROUPE_NAME}-prod-percentage`}
                    value={surplusPercentage}
                    disabled={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                    placeholder={'[0-100]'}
                />
            </Grid>
            <Grid
                item
                xs={nRowsForSurplus + nRowsForAlgorithm}
                className={classes.pool2mergedCells}
            >
                {surplusPercentage < 0 && (
                    <Alert severity="error">
                        La somme des pourcentages des groupes doit être
                        inférieure ou égale à 100.
                    </Alert>
                )}
            </Grid>
            <Grid item xs={nRowsForTools}></Grid>
        </ListItem>
    );
};

const NewPoolLine = ({ handleNewPool }) => {
    return (
        <ListItem>
            <Grid item xs={12}>
                <ListItem button onClick={handleNewPool}>
                    <AddIcon /> Ajouter un nouveau groupe
                </ListItem>
            </Grid>
        </ListItem>
    );
};

const getDuplicatedPoolNames = (pools) => {
    const names = pools.map((p) => p.name);
    const duplicatedNames = new Set();
    names.forEach((name, idx) => {
        if (names.indexOf(name) !== idx) duplicatedNames.add(name);
    });
    return Array.from(duplicatedNames);
};

const WarnginDuplicatedPoolNames = ({ pools }) => {
    const duplicatedNames = getDuplicatedPoolNames(pools);
    return (
        duplicatedNames.length > 0 && (
            <Alert severity="error" style={{ marginTop: '10px' }}>
                Plusieurs groupes ont le même nom : {duplicatedNames.join(', ')}
            </Alert>
        )
    );
};

const getConsumersNotInPool = (pools, consumers) => {
    const consumerInPools = pools.reduce((acc, pool) => {
        const poolConsumerIds = Object.keys(
            pool.withinPoolProductionParams
        ).map((e) => Number(e));
        acc.push(...poolConsumerIds);
        return acc;
    }, []);
    const consumersNotInPool = consumers.filter(
        (c) => !consumerInPools.includes(c.id)
    );
    return consumersNotInPool;
};

const WarnginConsumersNotInPool = ({ pools, consumers }) => {
    const consumersNotInPool = getConsumersNotInPool(pools, consumers);
    const message =
        consumersNotInPool.length > 1
            ? 'Ces participants ne sont pas dans un groupe :'
            : "Ce participant n'est pas dans un groupe :";
    return (
        consumersNotInPool.length !== 0 && (
            <Alert severity="error" style={{ marginTop: '10px' }}>
                {message}
                <ul>
                    {consumersNotInPool.map((consumer) => (
                        <li key={'no-param-for-' + consumer.dsoId}>
                            {consumer.dsoId} - {consumer.name}
                        </li>
                    ))}
                </ul>
            </Alert>
        )
    );
};

export const ByPoolParams = ({ setIsFormValid, formRef }) => {
    const dispatch = useDispatch();

    // Hooks ==================================================================
    let consumers = useSelector(selectActiveConsumersSinceLastMensiversary);

    const persistedParams = useSelector(selectPersistedParticipantsParameters);
    const persistedAllocationAlgorithmConfig = useSelector(
        selectPersistedAllocationAlgorithmConfig
    );
    const newAlgorithmType = useSelector(selectNewAllocationAlgorithmType);

    // Component state ========================================================
    const [pools, setPools] = useState([]);

    // use effect hooks =======================================================
    useEffect(
        function updateParametersIfConfigChange() {
            dispatch(getParticipantsAllocationParameters());
        },
        [persistedAllocationAlgorithmConfig]
    );

    useEffect(
        function loadConfFromStor() {
            if (newAlgorithmType !== AllocationAlgorithmTypeEnum.BY_POOL)
                return;
            dispatch(setNewParticipantsParameters({}));
            dispatch(getParticipantsAllocationParameters());
        },
        [newAlgorithmType]
    );

    useEffect(
        function loadConfigFromStore() {
            if (newAlgorithmType !== AllocationAlgorithmTypeEnum.BY_POOL)
                return;

            const poolsFromStore = [];
            for (const priority of Object.keys(persistedParams).sort()) {
                if (typeof persistedParams[priority] === 'number') {
                    return;
                }
                const poolParams = persistedParams[priority];
                if (!Object.hasOwn(poolParams, 'algorithm')) {
                    return;
                }
                poolsFromStore.push({
                    id: poolParams.id,
                    name: poolParams.name,
                    percentageProduction: poolParams.percentageProduction ?? 0,
                    surplusDestinationPoolName:
                        poolParams.surplusDestinationPoolName ?? '',
                    algorithm: poolParams.algorithm,
                    withinPoolProductionParams: {
                        ...poolParams.withinPoolProductionParams,
                    },
                });
            }
            if (poolsFromStore.length > 0) {
                setPools([...poolsFromStore]);
            }
        },
        [persistedParams]
    );

    useEffect(
        function setPoolFormIsValid() {
            if (newAlgorithmType !== AllocationAlgorithmTypeEnum.BY_POOL)
                return;

            const isPoolFormValid = () => {
                if (pools.lenght === 0) return false;

                const totalPercentage = pools.reduce(
                    (acc, pool) => acc + pool.percentageProduction,
                    0
                );
                if (totalPercentage > 100) return false;

                const poolNamesWithHigherPriority = [];
                for (const pool of pools) {
                    if (!pool.algorithm) return false;
                    if (!pool.surplusDestinationPoolName) return false;
                    if (
                        poolNamesWithHigherPriority.includes(
                            pool.surplusDestinationPoolName
                        )
                    ) {
                        return false;
                    }
                    poolNamesWithHigherPriority.push(pool.name);
                }

                if (getDuplicatedPoolNames(pools).length !== 0) return false;

                if (getConsumersNotInPool(pools, consumers).length !== 0)
                    return false;

                return true;
            };
            const isValid = isPoolFormValid();
            setIsFormValid(isValid);
        },
        [pools]
    );

    useEffect(
        function updateNewParametersForSaving() {
            if (newAlgorithmType !== AllocationAlgorithmTypeEnum.BY_POOL)
                return;

            const poolParams = {};
            for (const [index, pool] of pools.entries()) {
                const priority = index + 1;
                poolParams[priority] = {
                    id: pool.id,
                    name: pool.name,
                    percentageProduction: pool.percentageProduction,
                    surplusDestinationPoolName: pool.surplusDestinationPoolName,
                    algorithm: pool.algorithm,
                    withinPoolProductionParams: pool.withinPoolProductionParams,
                };
            }
            dispatch(setNewParticipantsParameters(poolParams));
        },
        [pools]
    );

    // Callbacks ==============================================================
    function handleNewPool() {
        let higherId = 0;
        if (pools.length) {
            const newGroupNames = pools
                .filter((p) => p.name.includes(NEW_POOL_NAME_PREFIX))
                .map(
                    (p) => Number(p.name.replace(NEW_POOL_NAME_PREFIX, '')) || 0
                );
            const newGroupIds = pools
                .filter((p) => p.id.includes(NEW_POOL_PREFIX))
                .map((p) => Number(p.id.replace(NEW_POOL_PREFIX, '')));
            higherId = Math.max(0, ...newGroupNames, ...newGroupIds);
        }
        const newPool = {
            id: NEW_POOL_PREFIX + (higherId + 1),
            name: NEW_POOL_NAME_PREFIX + (higherId + 1),
            percentageProduction: 0,
            surplusDestinationPoolName: SURPLUS_GROUPE_NAME,
            algorithm: null,
            withinPoolProductionParams: {},
        };
        setPools([...pools, newPool]);
    }

    function handleUpdateName(poolId, newName) {
        const poolIndex = pools.findIndex((pool) => pool.id === poolId);
        pools[poolIndex].name = newName;
        setPools([...pools]);
    }

    function handleUpdateAlgo(poolId, newAlgo) {
        const poolIndex = pools.findIndex((pool) => pool.id === poolId);
        pools[poolIndex].algorithm = newAlgo;
        setPools([...pools]);
    }

    function handleUpdateSurplusDestination(poolId, newDestination) {
        const poolIndex = pools.findIndex((pool) => pool.id === poolId);
        pools[poolIndex].surplusDestinationPoolName = newDestination;
        setPools([...pools]);
    }

    function handleUpdateProductionPercentage(poolId, newPercentage) {
        const poolIndex = pools.findIndex((pool) => pool.id === poolId);
        let percentage = Math.max(Number(newPercentage), 0);
        percentage = Math.min(percentage, 100);
        pools[poolIndex].percentageProduction = percentage;
        setPools([...pools]);
    }

    function handleMovePool(poolId, direction) {
        const poolIndex = pools.findIndex((pool) => pool.id === poolId);
        if (direction === UP) {
            movePoolUp(poolIndex);
        } else if (direction === DOWN) {
            movePoolUp(poolIndex + 1);
        } else if (direction === DELETE) {
            deletePool(poolIndex);
        } else {
            console.warn('Unknown direction to move pool:', direction);
        }
    }

    function movePoolUp(poolIndex) {
        const newPoolIndex = Math.max(0, poolIndex - 1);

        // If swich with a pool that where given the surplus, reset destination
        if (
            pools[newPoolIndex].surplusDestinationPoolName ===
            pools[poolIndex].name
        ) {
            pools[newPoolIndex].surplusDestinationPoolName = null;
        }

        // Switch
        pools.splice(newPoolIndex, 0, pools.splice(poolIndex, 1)[0]);
        setPools([...pools]);
    }

    function deletePool(poolIndex) {
        const isConfirmed = window.confirm(
            `Attention, cette action va supprimer le groupe '${pools[poolIndex].name}'...`
        );
        if (!isConfirmed) {
            return;
        }
        // Check if another pool give its surplus to the removed pool
        for (const pool of pools) {
            if (pool.surplusDestinationPoolName === pools[poolIndex].name) {
                pool.surplusDestinationPoolName = SURPLUS_GROUPE_NAME;
            }
        }

        pools.splice(poolIndex, 1);
        setPools([...pools]);
    }

    const updateParameter = (poolId, consumerId, param) => {
        const poolIndex = pools.findIndex((p) => p.id === poolId);
        const pool = pools[poolIndex];

        if (param && param !== '0') {
            pool.withinPoolProductionParams[consumerId] = Number(param);
        } else {
            delete pool.withinPoolProductionParams[consumerId];
        }
        setPools([...pools]);
    };

    // Renders & utils ========================================================
    return (
        <>
            <PoolDocumentation />
            <form ref={formRef}>
                <List aria-labelledby="nested-list-subheader">
                    <HeaderLine />
                    {pools.map((pool) => {
                        const handleParamChange =
                            (consumerId, isSelected) => (event) => {
                                let value;
                                if (typeof isSelected !== 'undefined') {
                                    value = isSelected;
                                } else {
                                    value = event.target.value?.replace(
                                        ',',
                                        '.'
                                    );
                                }
                                const param = Number(value);
                                updateParameter(pool.id, consumerId, param);
                            };

                        return (
                            <PoolLine
                                pool={pool}
                                pools={pools}
                                participants={consumers}
                                key={'pool-' + pool.id + '-line'}
                                handleUpdateName={handleUpdateName}
                                handleUpdateAlgo={handleUpdateAlgo}
                                handleUpdateProductionPercentage={
                                    handleUpdateProductionPercentage
                                }
                                handleUpdateSurplusDestination={
                                    handleUpdateSurplusDestination
                                }
                                handleMovePool={handleMovePool}
                                handleParamChange={handleParamChange}
                            />
                        );
                    })}
                    <SurplusLine pools={pools} />
                    <NewPoolLine handleNewPool={handleNewPool} />
                </List>
                <WarnginConsumersNotInPool
                    pools={pools}
                    consumers={consumers}
                />
                <WarnginDuplicatedPoolNames pools={pools} />
            </form>
        </>
    );
};
